import React, { useTransition } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages/Home'
import NewLead from './pages/NewLead'
import Politique from './pages/Politique'
import { HelmetProvider } from 'react-helmet-async';




function App() {
  const helmetContext = {};
  
  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/new-lead" component={NewLead} />
            <Route exact path="/politique_confidentialite" component={Politique} />
          </Switch>
        
      </Router>
    </HelmetProvider>
  );
}

export default App;
